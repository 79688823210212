@import "../../utils/_variables";

.make-visible {
  @include textShadow();
}

.make-visible-new {
  font-size: 18px;
}

.BigContainer {
  h1 {
    @include vw-pixels(('font-size': 38));
  }

  display: flex;
  flex-direction: column;
  // margin:  0px;
  padding: 80px 265px;
  position: relative;
  width: 100%;
  background-color: #322D38;

  @media #{$mobileAndTabletOnly} {
    margin: 130px 0;
  }

  .ContainerWrapper {
    display: flex;
    flex-direction: column;
    width: 100%;

    .ContainerSummary {
      display: flex;
      flex-direction: row;
      // align-items: center;

      .left {
        // 设计时宽度 982px，转换为 vw 单位
        width: 982px;
        // 如果设计时高度 588px 也按 1920px 宽度比例缩放：
        height: 588px;
        z-index: 10;
      }

      .right {
        // 右侧宽度 = 总宽度 - 左侧宽度（注意：这里 982 转换为比例计算）
        height: 588px;
        width: 416px;
        // 向左移动10px，10px 同样转换为 vw 单位
        margin-left: -20px;
        align-items: center;
        display: flex;
        justify-content: center;
        z-index: 1;
      }
    }

    .ContainerDetail {
      display: flex;
      flex-direction: row;
      width: 100%;
      margin-top: 60px;

      .ContainerDetailLeft {
        // width: 70%;
        width: 405px;
        display: flex;
        padding: 0 35px 0 35px;

        .ContainerDetailLeftGameSection {
          display: flex;
          flex-direction: column;
          align-items: center;

          width: 100%;

          .gameImage {
            display: flex;
            flex-direction: column;
            // margin: 0 64px 0 0;

            .ContainerDetailLeftGameSectionImageContainer {
              display: flex;
              align-items: center;
              width: 345px;
              height: 345px;
              justify-content: center;
            }

            img {
              // max-width: 150px;
              width: 345px;
              // height: 200px;
            }

            .ContainerDetailLeftGameSectionMore {
              background: #211C27;
              border: 2px solid #595261;
              width: 345px;
              height: 40px;
              border-radius: 20px;
              color: white;
              text-transform: uppercase;
              z-index: 10;
              font-size: 16px;
              cursor: pointer;
            }
          }

          img {
            margin: 0 0 20px 0;
            min-width: 216px;
          }
        }
      }

      .ContainerDetailRight {
        width: 70%;
        // flex:1
      }
    }
  }
}

.QuestContainer {
  display: flex;
  flex-direction: column;
  // margin: 0 400px 0 0;

  iframe {
    // margin: 0 0 0 0;
  }

  .DeliverablesContainer {
    max-width: 75vw;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    // margin: 1% 0 0 0;
    width: 100%;
    @include textShadow();

    .deliverablesList_title {
      width: 100%;
      align-items: flex-start;
      font-size: 18px;
      font-weight: bold;
      color: #FA3633;
      // line-height: 30px;
    }

    .deliverablesList {
      width: 100%;
      display: grid;
      grid-template-columns: 33.33% 33.33% 33.33%;
      @include vw-pixels(('font-size': 20));
      margin-top: 10px;
      row-gap: 20px; // 添加 20px 的行间距

      @media #{$mobileOnly} {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-evenly;
        align-items: center;
        @include vw-pixels(('font-size': 20));
      }

      .deliverable {
        flex-basis: 33.33%;
        min-width: 33.33%; // 修改为每行3个
        width: 100%;
        font-size: 16px;

        span {
          display: -webkit-box;
          -webkit-line-clamp: 2; // 最多显示2行
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis; // 超出显示省略号
          word-break: break-word;
          max-width: 100%;
        }

        @media #{$mobileOnly} {
          width: 60%;
        }

        min-width: 25%;
        color: white;
        font-weight: bold;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: baseline;

        .iconBg {
          min-width: 24px;
          min-height: 24px;
          @include vw-pixels(('width': 24,
              'height': 24,
              'border-radius': 50,
            ));
          background-repeat: no-repeat;
          margin: 0 21px 0 0;
          display: flex;
          // background: black;
          align-items: center;
          justify-content: center;
        }
      }

      img.icon {
        margin: auto;
        width: 100%;
      }
    }
  }

  @media #{$mobileAndTabletOnly} {
    margin: 0;

    .DeliverablesContainer {
      .deliverablesList {
        flex-wrap: nowrap;
        flex-direction: column;

        span.deliverable {
          width: 100%;
        }
      }
    }
  }

  .GameSection {
    h1 {
      @include vw-pixels(('font-size': 30));
    }

    @media #{$tabletAndLarger} {
      max-width: 65vw;
    }

    margin-top: 30px;
    display: flex;
    flex-direction: row;
    // padding: 0 56px 0 56px;

    .GameSectionTitle {
      width: 100%;
      align-items: flex-start;
      font-size: 20px;
      font-weight: bold;
      color: #FDFDFD;
      line-height: normal;

    }

    .GameSectionTitleDescription {
      margin: 30px 0 0 0;
      font-size: 13px;
    }

    .gameDesc {
      @include textShadow();
      width: 100%;

      h1 {
        margin: 0 0 18px 0;
      }

      * {
        white-space: normal;
      }
    }

    .gameImage {
      display: flex;
      flex-direction: column;
      margin: 0 64px 0 0;

      img {
        max-width: 150px;
      }
    }

    img {
      margin: 0 0 20px 0;
      min-width: 216px;
    }

    @media #{$mobileOnly} {
      flex-direction: column;
      align-items: center;
      padding: 0 20px;
      margin: 67px 0;

      .gameImage {
        margin: 0 0 64px 0;
        width: 216px;
      }
    }
  }

  .GameDescription {
    #text {
      @include vw-pixels(('font-size': 18));
    }

    .subtitle-text {
      margin-top: 25px;
      font-size: 18px;
      color: #F8F8F8;
    }

    display: flex;
    flex-direction: column;

    .gameItem {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      width: 100%;
      margin-top: 30px;

      .subtitle {
        width: 100%;
        align-items: flex-start;
        font-size: 18px;
        font-weight: bold;
        color: #FA3633;
        // line-height: 30px;
      }
    }

    @media #{$mobileOnly} {
      flex-direction: column;
      padding: 0 0 0 20px;

      .gameItem {
        font-size: 12px;
        margin: 0 0 30px 0;
        width: 100%;
      }
    }
  }

  // 

  .GameGallery {
    margin-top: 40px;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start; // 从左侧开始排列，不居中

    .galleryItem {
      margin: 5px;
      width: calc(50% - 10px); // 两项每行，总水平间隙10px
      border-radius: 10px;
      overflow: hidden; // 保证圆角对内部图片生效
      cursor: pointer;
      position: relative;

      img {
        width: 100%;
        height: auto;
        border-radius: 10px;
      }
    }
  }

  .EligibilityContainer {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 20px 0 0 0;
    // padding: 40px 10px 65px 10px;
    // text-align: center;

    h1 {
      width: 100%;
    }

    border-radius: 10px;
    @include textShadow();

    img {
      -webkit-filter: drop-shadow(3px 3px 3px #222);
      filter: drop-shadow(3px 3px 3px #222);
    }

    @media #{$mobileAndTabletOnly} {
      .eligibilityList {
        flex-wrap: wrap;
        margin: 0 37px;
      }

      .eligibilityItem {
        width: 20px;
        height: 20px;
        margin-left: 20px;
        // max-width: 50% !important;
      }
    }

    .eligibilityList {
      align-items: baseline;
      margin-top: 20px;
      display: flex;
      flex-direction: row;
      // justify-content: space-evenly;
      @include textShadow();
      width: 100%;

      @media #{$mobileOnly} {
        flex-direction: column;
        align-items: center;
        justify-content: center;
      }

      .eligibilityItem {
        @media #{$mobileOnly} {
          margin-top: 20px;
          font-size: 15px;
        }

        // width: 140px;
        margin-left: 10px;
        display: flex;
        flex-basis: 1;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        font-weight: 900;

        @include vw-pixels(('font-size': 24));

        align-items: center;
        // max-width: 25%;

        img {
          width: 23px;
          height: 23px;
          margin: 8px 0;
          object-fit: contain;
        }

        span.minACCV {
          margin: 0 0 0 0;
          font-size: 24;
        }

        span.label {
          text-transform: uppercase;
          @include vw-pixels(('font-size': 12));
          font-weight: bold;
          // letter-spacing: 1px;
        }

        .GamesPlayedModal_button {
          cursor: pointer;
          color: $colorWhite;
          text-transform: uppercase;
          @include vw-pixels(('font-size': 14));
          font-weight: bold;
          letter-spacing: 1px;
        }
      }
    }
  }

  .faqSection {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: left;
    margin: 30px 0 30px 0px;

    @media #{$tabletAndLarger} {
      max-width: 65vw;
    }

    @media #{$mobileAndTabletOnly} {
      margin: 36px 0 0 0;
    }
  }

  .gameGalleryBar {
    display: flex;
    justify-content: center;
    margin: 32px 0 0 0;

    button {
      width: 216px;
    }
  }
}

.MoreQuestsContainer {
  width: 100%;
  margin: 192px 0 0 0;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  min-height: 30%;

  h1 {
    margin: 0 0 34px 0;
  }
}

.bg-manager {
  position: fixed;
  width: 100vw;
  height: 100%;
  z-index: .5;
}

.common_title {
  width: 100%;
  align-items: flex-start;
  font-size: 18px;
  font-weight: bold;
  color: #FA3633;
  line-height: 30px;
}

.platformSelectedList {

  margin-top: 30px;

  .platform-item-twitch-btn-selector {
    margin-top: 5px;
    width: 160px;
    height: 40px;
    background: #FA3633;
    border-radius: 50px;
    color: #ffffff;
    cursor: pointer;
    // transition: all 0.3s ease;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;

  }

  .platform-item-twitch-btn-selector.active {
    background: #FA3633;
  }

  .platform-item-twitch-btn-icon-selector {
    width: 22px;
    height: 23px;
    fill: white;
  }

  .platform-item-twitch-btn-selector-text {
    margin-left: 10px;
    font-size: 14px;
    color: #fdfdfd;
    line-height: 19px;
  }
}

.verifying-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0,0,0,0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9998;
}

.verifying-modal {
  background: #322D38;
  border-radius: 8px;
  padding: 20px 30px;
  color: #FFFFFF;
  font-size: 24px;
  font-weight: bold;
}